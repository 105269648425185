<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Líneas Vehiculares</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Vehículos</li>
                  <li class="breadcrumb-item active">Líneas Vehiculares</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h6 class="mb-0">Formulario {{ accion }} Líneas Vehiculares</h6>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="marca">Marca</label>
                          <select
                            class="form-control form-control-sm"
                            id="marca"
                            v-model="form.marca"
                            :class="
                              $v.form.marca.$invalid ? 'is-invalid' : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="marca in listasForms.marcas"
                              :key="marca.numeracion"
                              :value="marca.numeracion"
                            >
                              {{ marca.descripcion }}
                            </option>
                          </select>
                          <div class="error" v-if="!$v.form.marca.required">
                            Seleccione una marca
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="modelo">Línea</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="modelo"
                            v-model="form.linea"
                            :class="
                              $v.form.linea.$invalid ? 'is-invalid' : 'is-valid'
                            "
                          />
                          <div class="error" v-if="!$v.form.linea.required">
                            Diligencie una Línea
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="marca">Tipo Combustible</label>
                          <select
                            class="form-control form-control-sm"
                            id="marca"
                            v-model="form.tipo_combustible"
                            @change="valPreguntas()"
                            :class="
                              $v.form.tipo_combustible.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tipo_combustible in listasForms.tipo_combustibles"
                              :key="tipo_combustible.numeracion"
                              :value="tipo_combustible.numeracion"
                            >
                              {{ tipo_combustible.descripcion }}
                            </option>
                          </select>
                          <div
                            class="error"
                            v-if="!$v.form.tipo_combustible.required"
                          >
                            Seleccione un Tipo de Combustible
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <label>Estado</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form.estado"
                          disabled
                        >
                          <option value="1">Autorizado</option>
                          <option value="2">No Autorizado</option>
                        </select>
                      </div>
                    </div>
                    <div class="row" v-if="form.tipo_combustible == 1">
                      <div class="col-md-1"></div>
                      <div class="col-md-10">
                        <div class="card card-navy">
                          <div class="card-header pt-2 pb-2">
                            <div class="row">
                              <div class="col-md-11">
                                <i class="fas fa-question-circle"></i>
                                <b> Preguntas</b>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="card-body">
                              <div
                                class="row"
                                v-for="pregunta in listasForms.preguntas"
                                :key="pregunta.id"
                              >
                                <div class="form-group">
                                  <div
                                    class="
                                      custom-control
                                      custom-switch
                                      custom-switch-off-gray
                                      custom-switch-on-success
                                    "
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      v-model="
                                        form.preguntas[pregunta.numeracion]
                                      "
                                      :id="'pregunta_' + pregunta.numeracion"
                                      :checked="pregunta.estado"
                                    />
                                    <label
                                      class="custom-control-label"
                                      :for="'pregunta_' + pregunta.numeracion"
                                      >{{ pregunta.descripcion }}</label
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="savePreguntas()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "LineasVehicularesForm",
  components: {},
  data() {
    return {
      form: {
        marca: null,
        linea: null,
        tipo_combustible: null,
        estado: null,
        preguntas: [],
      },
      listasForms: {
        marcas: [],
        tipo_combustibles: [],
        preguntas: [],
      },
      metodo: null,
      accion: null,
    };
  },
  validations: {
    form: {
      marca: {
        required,
      },
      linea: {
        required,
      },
      tipo_combustible: {
        required,
      },
      preguntas: {
        required,
      },
    },
  },
  methods: {
    back() {
      return this.$router.replace("/Admin/LineasVehiculares");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        let pre = this.$route.params.data_edit.preguntas;

        this.form.preguntas = [];
        for (let i = 0; i < pre.length; i++) {
          this.form.preguntas[pre[i].pregunta] = pre[i].estado;
        }
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
    },

    savePreguntas() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/lineasVehiculares",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.$swal({
              icon: "success",
              title: "La Línea del vehículo se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    valPreguntas() {
      for (let i = 0; i < this.listasForms.preguntas.length; i++) {
        this.listasForms.preguntas[i].estado = 0;
        this.form.preguntas[this.listasForms.preguntas[i].numeracion] = 0;
      }
      if (this.form.tipo_combustible == 1) {
        this.form.estado = null;
      } else {
        this.form.estado = 1;
      }
    },

    getListaMarca() {
      axios.get("/api/lista/12").then((response) => {
        this.listasForms.marcas = response.data;
      });
    },

    getListaTipoCombustible() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.tipo_combustibles = response.data;
      });
    },

    getListaPreguntas() {
      axios.get("/api/lista/146").then((response) => {
        this.listasForms.preguntas = response.data;
      });
    },
  },
  mounted() {
    this.init();
    this.getListaMarca();
    this.getListaTipoCombustible();
    this.getListaPreguntas();
  },
};
</script>